#projects {
  width: 100%;
  padding: 0 15%; /* Match the padding from intro.css */
  margin: 0;
}

.section-header {
  display: flex;
  align-items: center;
  padding-bottom: 42px;
  width: 100%;
  position: relative;
}

.section-header::after {
  content: "";
  display: block;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  margin-top: 0;
  background-color: var(--lightest-navy);
}

.section-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 46px;
  font-weight: bold;
  white-space: nowrap;
}

.projects-wrapper {
  width: 100%;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 40px 0;
}

.project-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.project-image-container {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.project-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.08);
}

.project-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-content {
  padding: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.project-title {
  font-family: "NTR", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: var(--lightest-slate);
  margin: 0;
}

.project-links {
  display: flex;
  gap: 16px;
}

.project-link {
  color: var(--green-bright);
  transition: color 0.2s ease;
}

.project-link:hover {
  color: #9ffff1;
}

.project-description {
  color: var(--slate);
  line-height: 1.6;
  font-family: "NTR", sans-serif;
  font-size: 16px;
  white-space: pre-line;
  margin-bottom: 20px;
  flex-grow: 1;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: auto;
}

.tech-tag {
  color: var(--green-bright);
  font-size: 14px;
  font-family: "NTR", sans-serif;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 600px) {
  #projects {
    padding: 0 24px;
  }
  
  .section-header::after {
    display: none;
  }
  
  .section-title {
    font-size: 32px;
  }

  .project-container {
    grid-template-columns: 1fr;
  }
  
  .project-card {
    max-width: 100%;
  }
}


/* #projects {
  width: 100%;
  padding: 0 15%;
  margin: 0;
}

.section-header {
  display: flex;
  align-items: center;
  padding-bottom: 42px;
  width: 100%;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.project-card {
  background: var(--bg-secondary);
  border-radius: 16px;
  transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card:hover {
  transform: translateY(-5px);
  background: var(--bg-tertiary);
}

.project-image-container {
  width: 100%;
  height: 250px; /* Increased from 200px 
  overflow: hidden;
  position: relative;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 80%; /* This ensures the content takes full height 
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.project-title {
  font-family: var(--font-display);
  font-size: 24px;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.project-links {
  display: flex;
  gap: 16px;
}

.project-link {
  color: var(--accent);
  transition: all 0.2s ease;
}

.project-link:hover {
  color: var(--text-primary);
  transform: translateY(-2px);
}

.project-description {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 16px;
  white-space: pre-line; /* This handles the \n newlines 
  margin: 10px 0; /* Adjust margins 
  white-space: pre-line;
  margin-bottom: auto; /* This pushes the tech stack down 
  flex-grow: 1;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: auto;
}

.tech-tag {
  color: var(--accent);
  font-size: 13px;
  font-family: var(--font-mono);
  background-color: var(--green-tint);
  padding: 4px 8px;
  border-radius: 4px;
}

/* Navigation buttons 
.nav-button {
  background: none;
  border: 1px solid var(--accent);
  color: var(--accent);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
}

.nav-button:hover {
  background: var(--green-tint);
}

.nav-button.prev {
  position: absolute;
  left: -60px;
}

.nav-button.next {
  position: absolute;
  right: -60px;
}

/* Carousel indicators 
.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--text-secondary);
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.indicator.active {
  opacity: 1;
  background: var(--accent);
  width: 24px;
  border-radius: 4px;
}

@media only screen and (max-width: 1200px) {
  .project-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  #projects {
    padding: 0 24px;
  }
  
  .project-container {
    grid-template-columns: 1fr;
  }
  
  .nav-button.prev {
    left: -40px;
  }
  
  .nav-button.next {
    right: -40px;
  }
} */